import Vue from 'vue';
import Vuex from 'vuex';
let webScoketIp=require('../assets/json/ipAddress.json').webScoket;
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    webSocket: null
  },
  mutations: {
    setWebSocket(state, webSocket) {
      state.webSocket = webSocket;
    }
  },
  actions: {
    createWebSocket({ commit },userId) {

      const webSocket = new WebSocket(webScoketIp+"/"+userId);
      commit('setWebSocket', webSocket);
    }
  }
});
 
export default store;