import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/richText',
    name: '新增文章',
    // component: () => import('../views/article/addArticle.vue')
    component: () => import('../components/richText.vue')
  },
  {
    path:"/test",
    name: '测试',
    component: () => import('../components/test.vue')
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('../components/login.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue'),
    children: [
      {
        path: 'index',
        name: '主页',
        component: () => import ('../views/index/index.vue')
      },
      {
        path: "message",
        name: '消息中心',
        component: () => import('../views/message/message.vue')
      },
      {
        path:"feedback",
        name: '反馈中心',
        component: () => import('../components/null.vue'),
        children: [
          {
            path: 'complain',
            name: '投诉中心',
            component: () => import('../views/feedback/complain.vue')
          },
          {
            path: 'praise',
            name: '表扬中心',
            component: () => import('../views/feedback/praise.vue')
          }
        ]
      },
      {
        path:"notify",
        name: '通知中心',
        component: () => import('../components/null.vue'),
        children: [
          {
            path: 'notifyList',
            name: '通知列表',
            component: () => import('../views/notify/notifyList.vue')
          },
        ]
      },
      {
        path: 'audit',
        name: '审核中心',
        component: () => import('../components/null.vue'),
        children: [
          {
            path: 'perceptionAudit',
            name: '留言感受审核',
            component: () => import('../views/audit/perceptionAudit.vue')
          },
          {
            path: 'articleAudit',
            name: '文章审核',
            component: () => import('../views/audit/articleAudit.vue')
          },
          {
            path: 'articleAudit/articleView',
            name: '文章预览',
            component: () => import('../views/audit/articleView.vue')
          },
          {
            path:"schemeAudit",
            name: '方案审核',
            component: () => import('../views/audit/schemeAudit.vue')
          }
        ]
      },
      {
        path: 'workbench',
        name: '工作台',
        component: () => import('../components/null.vue'),
        children: [
          {
            path: "firstAid",
            name: '急救知识管理',
            component: () => import('../views/firstAid/firstAidList.vue')

          },
          {
            path: "firstAid/add",
            name: '新增急救知识',
            component: () => import('../views/firstAid/addOrUpdate.vue')
          },
          {
            path: "firstAid/view",
            name: '急救知识详情',
            component: () => import('../views/firstAid/addOrUpdate.vue')
          },
          {
            path: "firstAid/update",
            name: '编辑急救知识',
            component: () => import('../views/firstAid/addOrUpdate.vue')
          },
          

          {
            path: "audit",
            name: '评论审核',
            component: () => import('../views/audit/perceptionAudit.vue')
 
          },
          {
            path: "knowledge",
            name: '健康知识管理',
            component: () => import('../views/knowledge/knowledge.vue')
          },
          {
            path: 'questionnaire',
            name: '问卷管理',
            component: () => import('../views/questionnaire/questionnaire.vue')
          },
          {
            path: 'article',
            name: '文章管理',
            component: () => import('../views/article/articleList.vue')
          },
          {
            path: 'article/addOrUpdate',
            name: '新增文章',
            component: () => import('../views/article/addArticle.vue')
          },
          {
            path: 'questionnaire/addQuestionnaire',
            name: '新增问卷',
            component: () => import('../views/questionnaire/addQuestionnaire.vue')
          },
          {
            path: 'questionnaire/viewQuestionnaire/:questionnaireId',
            name: '问卷详情',
            component: () => import('../views/questionnaire/viewDetails.vue')
          },
          {
            path: 'questionnaire/preview',
            name: '问卷预览',
            component: () => import('../views/questionnaire/viewDetails.vue')
          },
          {
            path: 'questionnaire/editQuestionnaire/:questionnaireId',
            name: '问卷编辑',
            component: () => import('../views/questionnaire/addQuestionnaire.vue')
          },
          {
            path: 'label',
            name: '标签管理',
            component: () => import('../views/label/labelList.vue')
          }
        ]
      },
      {
        path: 'task',
        name: '任务管理',
        component: () => import('../components/null.vue'),
        children: [
          {
            path: "evaluate",
            name: '健康评估',
            component: () => import('../views/task/evaluate.vue'),
          }, {
            path: "preach",
            name: '宣讲任务',
            component: () => import('../views/task/preach.vue')
          }, {
            path: "complainAndPraise",
            name: '投诉与表扬',
            component: () => import('../views/task/complainAndPraise.vue')
          }, {
            path: "schemeTask",
            name: '制定方案',
            component: () => import('../views/task/schemeTask.vue')
          }, {
            path: "serviceClose",
            name: '服务到期结案',
            component: () => import('../views/task/serviceClose.vue')
          }, {
            path: "followUp",
            name: '随访',
            component: () => import('../views/task/followUp.vue')
          }, {
            path: "foodReview",
            name: '饮食点评',
            component: () => import('../views/task/foodReview.vue')
          },
          {
            path:"stageReport",
            name:"阶段报告",
            component: () => import('../views/task/stageReport.vue')
          }
        ]
      },
      {
        path: "schemeMaking",
        name: "方案制定",
        component: () => import('../components/null.vue'),
        children: [
          {
            path: "item",
            name: "项目管理",
            component: () => import("../views/schemeMaking/item.vue")
          },
          {
            path: "template",
            name: "模板管理",
            component: () => import("../views/schemeMaking/template.vue")
          }, {
            path: "template/viewTemplate/:templateId",
            name: "模板详情",
            component: () => import("../views/schemeMaking/viewTemplate.vue")
          }, {
            path: "template/handleTemplate/:templateId",
            name: "模板操作",
            component: () => import("../views/schemeMaking/addTemplate.vue")
          },
          {
            path: "scheme",
            name: "方案管理",
            component: () => import("../views/schemeMaking/scheme.vue")
          },
          {
            path: "scheme/viewScheme/:schemeId",
            name: "方案详情",
            component: () => import("../views/schemeMaking/schemeDetails.vue")
          },
          {

            path: "scheme/addScheme/chooseTemplate",
            name: "选择模板",
            component: () => import("../views/schemeMaking/chooseTemplate.vue")
          },
          {
            path: 'scheme/addScheme',
            name: "添加方案",
            component: () => import("../views/schemeMaking/addSchemeOrDraft.vue")
          },
        ]
      }, {
        path: "patient",
        name: "患者管理",
        component: () => import("../components/null.vue"),
        children: [
          {
            path: "/patient/patientHandle",
            name: "患者列表",
            component: () => import("../views/patient/patientList.vue")
          },
          {
            path: "/patient/addPatientTask",
            name: "新增患者任务",
            component: () => import("../views/patient/patientTask.vue")
          },
        ]
      }, {
        path: 'annex',
        name: "素材管理",
        component: () => import("../components/null.vue"),
        children: [
          {
            path: "annexList",
            name: "素材列表",
            component: () => import("../views/annex/annexList.vue"),
          },
          {
            path: "annex/upload",
            name: "上传文件",
            component: () => import("../components/uploadBigFile.vue"),
          }
        ]
      }, {
        path: "statsCentral",
        name: "统计中心",
        component: () => import("../components/null.vue"),
        children: [
          {
            path: "statistic",
            name: "统计分析",
            component: () => import("../views/statsCentral/statistic.vue")
          },
        ]
      }, {
        path: "sys",
        name: "系统设置",
        component: () => import("../components/null.vue"),
        children: [
          {
            path: "userSetting",
            name: "用户管理",
            component: () => import("../views/system/user.vue")
          },
          {
            path: "roleSetting",
            name: "角色管理",
            component: () => import("../views/system/role.vue")
          },
          {
            path:"menuSetting",
            name: "菜单管理",
            component: () => import("../views/system/menu.vue")
          },
          {
            path: "permissionSetting",
            name: "权限管理",
            component: () => import("../views/system/permission.vue")
          },
          {
            path: "hospitalSetting",
            name: "医院管理",
            component: () => import("../views/system/hospital.vue")
          },
          {
            path: "sectionSetting",
            name: "科室管理",
            component: () => import("../views/system/section.vue")
          },
          {
            path: "servePack",
            name: "服务包管理",
            component: () => import("../views/system/servicePack.vue")
          },

        ]
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: () => import('../components/404.vue')
  }
]
const router = new VueRouter({
  routes
});
router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('token');
  if (token) {
    next()
  } else if (to.path === '/login') {
    next()
  } else {
    next('/login')
  }
})
export default router
